import React from 'react'
import { SideNav, CloseButton, SideNavElement } from '../../styles/navbar/NavBarStyles'

const SideBar = props => {
    const { open, openSideBar, navLinks, mobile } = props
    const linksList = Object.entries(navLinks)

    const renderLinks = () => {
        return (
            <div style={{ margin: '1rem', padding: '1rem', color: 'white', fontFamily: 'kabel' }}>
                { linksList.map( link => {
                const name = link[0]
                const address = link[1]

                    return (
                        <SideNavElement key={name}> <a href={address}> {name.toUpperCase()} </a> </SideNavElement>
                    )}
                )}
                <h2> Contact </h2>
                <ul> Phone: <a  href="tel:850-654-5000" style={{ color: 'white', textDecoration: 'none' }}>(850)-654-5000</a> </ul>
                <ul> Address: </ul>
                <div style={{ cursor: 'pointer' }} onClick={() => window.open('https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.google.com%2Fmaps%2Fdir%2F%3Fapi%3D1%26destination%3D30.395692844622%252C-86.484153051125%26fbclid%3DIwAR3EJbFZbNlHdjFKCu5X8xGA9ARolhGn8W4mutNBdTBczoaLmqWMh-yB3f8&h=AT34L27kH1MWUXTxmZXY1xmlcnmslaqzfjSeVT5mbv_pd8LXr83ol5Sgel9-HJjJElA4goLVHHoez707Rh95SjblhrK3HVJaAJW8X1PAN3nmGXFzSuewogR9c_6gE1RnBH072w', '_blank')}>
                    <ul style={{ marginLeft: '1rem' }}> 225 Main Street </ul>
                    <ul style={{ marginLeft: '1rem' }}> Suit 4 </ul>
                    <ul style={{ marginLeft: '1rem' }}> Destin, FL 32541 </ul>
                </div>
                <h2> Hours of Operation </h2>
                <ul> Tue, Wed, Fri : 9am - 4pm </ul>
                <ul> Thurs : 11am - 2pm </ul>
                <ul> Sat : 9am - 2pm </ul>
                <div style={{ margin: '2rem 2rem 5rem', width: '15rem'}}>
                        <p> Late appointments available upon request </p>
                        <p> Closed Federal Holidays</p>
                </div>
                <div>
                    <p> Note: This site is still under development </p>
                </div>
            </div>
        )
    }

    return (
        <SideNav open={open} mobile={mobile}>
            <CloseButton> <div onClick={ () => { openSideBar()}}> x </div> </CloseButton>
            {renderLinks()}
        </SideNav>
    )
}

export default SideBar
